import { SAVE_AS_DRAFT_CONTENT, SAVE_AS_DRAFT_SEGMENT } from './constants';

const initialState = {
  pdfPages: [],
  segments: [],
};

export default function saveAsDraftReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_AS_DRAFT_CONTENT:
      return { ...state, pdfPages: action.payload };
    case SAVE_AS_DRAFT_SEGMENT:
      return { ...state, segments: action.payload };

    default:
      return state;
  }
}
