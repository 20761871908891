import { HIDE_ERROR_LOG_REPORT_MODAL, SHOW_ERROR_LOG_REPORT_MODAL } from './constants';

const initialState = {
  showErrorReportModal: false,
  errorId: null,
  error: null,
};

export default function errorLogReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_ERROR_LOG_REPORT_MODAL:
      return { ...state, showErrorReportModal: true, ...action.payload };

    case HIDE_ERROR_LOG_REPORT_MODAL:
      return {
        ...state,
        showErrorReportModal: false,
        errorId: null,
        error: null,
      };

    default:
      return state;
  }
}
