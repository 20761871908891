import { SET_PDF_UPLOAD_LIST, SET_DRAG_OBJ, SET_PDF_REQUEST_ID, SET_SELECTED_PDF_LIST_ID } from './constants';

const initialState = {
  list: [],
  dragEle: {},
  requestId: '',
  selectedPdfListId: '',
};

export default function pdfUploadReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PDF_UPLOAD_LIST:
      return { ...state, list: action.payload };
    case SET_DRAG_OBJ:
      return { ...state, dragEle: action.payload };
    case SET_PDF_REQUEST_ID:
      return { ...state, requestId: action.payload };
    case SET_SELECTED_PDF_LIST_ID:
      return { ...state, selectedPdfListId: action.payload };
    default:
      return state;
  }
}
