import {
  COMPOSE_IMAGE_MEDIA,
  COMPOSE_SCREEN_HEADING,
  COMPOSE_SCREEN_LIST,
  COMPOSE_SCREEN_PARAGRAPH,
  COMPOSE_VECTOR_MEDIA,
  COMPOSE_VIDEO_MEDIA,
  RESET_COMPOSE_SCREEN_PROJECT_SELECTION,
  RESET_SCRIPT_PROJECT_SELECTION,
  RESET_UPLOADDOC_PROJECT_SELECTION,
  UPLOADDOC_PROJECT_SELECTION,
  WITH_SCRIPT_PROJECT_SELECTION,
} from './constants';

const initialState = {
  withScript: {
    script: '',
    isManaul: null,
    externalVoice: null,
  },
  uploadDocument: {
    doc: null,
    externalVoice: null,
    docError: '',
  },
  composeScreen: {
    heading: [],
    paragraph: { value: '', error: '' },
    list: [],
    media: {
      videoMedia: [{ id: 'VD01' }],
      imageMedia: [{ id: 'IM01' }],
      illustrationMedia: [{ id: 'IL01' }],
    },
  },
};

export default function projectSelectionReducer(state = initialState, action) {
  switch (action.type) {
    case WITH_SCRIPT_PROJECT_SELECTION:
      return { ...state, withScript: { ...state.withScript, ...action.payload } };

    case RESET_SCRIPT_PROJECT_SELECTION:
      return { ...state, withScript: initialState.withScript };

    case UPLOADDOC_PROJECT_SELECTION:
      return {
        ...state,
        uploadDocument: {
          ...state.uploadDocument,
          ...action.payload,
        },
      };

    case RESET_UPLOADDOC_PROJECT_SELECTION:
      return { ...state, uploadDocument: initialState.uploadDocument };

    case COMPOSE_SCREEN_HEADING:
      return {
        ...state,
        composeScreen: {
          ...state.composeScreen,
          heading: action.payload,
        },
      };

    case COMPOSE_SCREEN_PARAGRAPH:
      return {
        ...state,
        composeScreen: {
          ...state.composeScreen,
          paragraph: action.payload,
        },
      };
    case COMPOSE_SCREEN_LIST:
      return {
        ...state,
        composeScreen: {
          ...state.composeScreen,
          list: action.payload,
        },
      };
    case RESET_COMPOSE_SCREEN_PROJECT_SELECTION:
      return { ...state, composeScreen: initialState.composeScreen };

    case COMPOSE_VIDEO_MEDIA:
      return {
        ...state,
        composeScreen: {
          ...state.composeScreen,
          media: {
            ...state.composeScreen.media,
            videoMedia: action.payload,
          },
        },
      };
    case COMPOSE_IMAGE_MEDIA:
      return {
        ...state,
        composeScreen: {
          ...state.composeScreen,
          media: {
            ...state.composeScreen.media,
            imageMedia: action.payload,
          },
        },
      };

    case COMPOSE_VECTOR_MEDIA:
      return {
        ...state,
        composeScreen: {
          ...state.composeScreen,
          media: {
            ...state.composeScreen.media,
            illustrationMedia: action.payload,
          },
        },
      };

    default:
      return state;
  }
}
