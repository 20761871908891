import { GET_REMAINING_FEATURES } from './constants';

const initialState = {
  combination: {},
  defaultFeatures: {},
  remainingTotalVideos: 0,
  activeSubscription: false,
  remainingVideoDurationInSeconds: 0,
  remainingRenderDurationInSeconds: 0,
};

export default function remainingFeaturesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_REMAINING_FEATURES:
      return { ...state, ...action.payload };

    default:
      return state;
  }
}
