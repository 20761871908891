import { SHOW_CONTACT_SALES_MODAL } from './constants';

const initialState = {
  contactSales: { message: '', title: '' },
};

export default function genericReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_CONTACT_SALES_MODAL: {
      return {
        ...state,
        contactSales: {
          ...state.contactSales,
          title: action.payload.title,
          message: action.payload.message,
        },
      };
    }

    default:
      return state;
  }
}
