import axios from 'axios';
import axiosRetry from 'axios-retry';
import { dispatchStore } from '../services/helper';

export const Axioslib = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});
export const AxioslibUser = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

Axioslib.interceptors.request.use(
  function (config) {
    config.headers.frontedurl = window.location.href;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

AxioslibUser.interceptors.request.use(
  function (config) {
    config.headers.frontedurl = window.location.href;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosRetry(Axioslib, {
  retries: 3,
  retryDelay: retryCount => {
    return retryCount * 5000;
  },
  retryCondition: error => {
    return error.response.status === 500;
  },
});

Axioslib.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error?.response?.data?.errorId) {
      const errorLog = error.response.data;
      dispatchStore.showErrorLogReportModal(errorLog);
    }
    return Promise.reject(error);
  }
);

AxioslibUser.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error?.response?.data?.errorId) {
      const errorLog = error.response.data;
      dispatchStore.showErrorLogReportModal(errorLog);
    }
    return Promise.reject(error);
  }
);

// export default { Axioslib, AxioslibUser};
