import {
  SET_CACHE_PROJECT,
  SET_SCREEN,
  SET_PROJECT,
  SET_CURRENT,
  SET_VOICE,
  SET_TEMPLATE,
  SET_CLIENT_SETTINGS,
  UPDATE_PREVIEW_RESULT,
} from './constants';

const initialState = {
  cacheProject: {},
  screen: {},
  project: {},
  current: {},
  voice: {
    emotion: 'Conversational',
    voiceRate: 0,
    selectedVoice: {
      voiceId: 'en-UK-juliet',
      displayName: 'Juliet (F)',
      locale: 'en-UK',
      displayLanguage: 'English',
      accent: 'UK',
      description: 'Middle-Aged',
      gender: 'Female',
      availableStyles: ['Conversational'],
    },
  },
  template: {},
  clientSettings: {},
};

export default function editProjectReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CACHE_PROJECT: {
      return {
        ...state,
        cacheProject: action.payload,
      };
    }
    case SET_PROJECT: {
      return {
        ...state,
        project: action.payload,
      };
    }
    case SET_SCREEN: {
      return {
        ...state,
        screen: action.payload,
      };
    }
    case SET_CURRENT: {
      return {
        ...state,
        current: action.payload,
      };
    }
    case SET_VOICE: {
      return {
        ...state,
        voice: action.payload,
      };
    }
    case SET_TEMPLATE: {
      return {
        ...state,
        template: action.payload,
      };
    }
    case SET_CLIENT_SETTINGS: {
      return {
        ...state,
        clientSettings: action.payload,
      };
    }
    case UPDATE_PREVIEW_RESULT: {
      const { projectId, moduleId, slideId, screenId, mediaurl } = action.payload;

      if (!state.project.ProjectData[projectId]?.Modules?.[moduleId]?.slides?.[slideId]?.Screens?.[screenId]) {
        return state;
      }

      let screenData = state.screen;

      if (state.current.moduleName === moduleId && state.current.slideName === slideId && state.current.screenName === screenId) {
        screenData['mediaurl'] = mediaurl;
      }

      return {
        ...state,
        project: {
          ...state.project,
          ProjectData: {
            ...state.project.ProjectData,
            [projectId]: {
              ...state.project.ProjectData[projectId],
              Modules: {
                ...state.project.ProjectData[projectId].Modules,
                [moduleId]: {
                  ...state.project.ProjectData[projectId].Modules[moduleId],
                  slides: {
                    ...state.project.ProjectData[projectId].Modules[moduleId].slides,
                    [slideId]: {
                      ...state.project.ProjectData[projectId].Modules[moduleId].slides[slideId],
                      Screens: {
                        ...state.project.ProjectData[projectId].Modules[moduleId].slides[slideId].Screens,
                        [screenId]: {
                          ...state.project.ProjectData[projectId].Modules[moduleId].slides[slideId].Screens[screenId],
                          mediaurl,
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
        screen: screenData,
        previewGeneratedOf: `${projectId}-${moduleId}-${slideId}-${screenId}`,
      };
    }
    default:
      return state;
  }
}
