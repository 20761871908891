export const INITIALIZEPROJECT = 'initializeproject';
export const PROJECTFROMLOCALSTORAGE = 'projectfromlocalstorage';
export const ADDMODULE = 'addmodule';
export const REMOVEMODULE = 'removemodule';
export const SELECTMODULE = 'selectmodule';
export const RENAMEMODULE = 'renamemodule';
export const ADDSLIDE = 'addslide';
export const REMOVESLIDE = 'removeslide';
export const SELECTSLIDE = 'selectslide';
export const RENAMESLIDE = 'renameslide';
export const ADDSCREEN = 'addscreen';
export const REMOVESCREEN = 'removescreen';
export const SELECTSCREEN = 'selectscreen';
export const RENAMESCREEN = 'renamescreen';
export const EDITAUDIOSCRIPT = 'editaudioscript';
export const SELECTOST = 'selectost';
export const EDITOST = 'editost';
export const ADDFEATURE = 'addfeature';
export const EDITFEATURE = 'editfeature';
export const DELETEFEATURE = 'deletefeature';
export const SELECTIMAGE = 'selectimage';
export const SELECTILLUSTRATION = 'selectillustration';
export const SELECTVIDEO = 'selectvideo';
export const EDITASSET = 'editasset';
export const EDITSCREENDURATION = 'editscreenduration';
export const UPDATETEMPLATEURL = 'updatetemplateurl';
export const UPDATETEMPLATEURLPREVIEW = 'updatetemplateurlpreview';
export const SETOSTFONT = 'setostfont';
export const CHANGEPROJECTNAME = 'changeprojectname';
export const EDITPROJECT = 'editproject';
export const EDITMODE = 'editmode';
export const PREVIOUSACTIVE = 'previousactive';
export const EMPTYPROJECT = 'emptyproject';
export const SETOSTFONTFORNEWSCREENS = 'setostfontfornewscreens';
export const UPDATEOSTFONT = 'updateostfont';
export const SET_VIDEO_SOURCE = 'SET_VIDEO_SOURCE';
/* editProject */
export const SET_CACHE_PROJECT = 'SET_CACHE_PROJECT';
export const SET_ACTIVE_SEGMENT = 'SET_ACTIVE_SEGMENT';
export const SET_ACTIVE_PROJECT = 'SET_ACTIVE_PROJECT';
//project Style
export const TOGGLERIGHTSCREEN = 'togglerightscreen';
export const TOGGLELEFTSCREEN = 'toggleleftscreen';
export const DISPLAYMODE = 'displaymode';
// Preview reducer
export const INITIALIZEPREVIEW = 'initializepreview';
export const SELECTACTIVESCREEN = 'selectactivescreen';
export const SELECTACTIVESLIDE = 'selectactiveslide';
export const SELECTACTIVEMODULE = 'selectactivemodule';
export const PREVIEW_UPDATEFEATURE = 'updatefeature';
export const PREVIEW_SETOSTFONT = 'previewsetostfont';
export const UPDATE_AUDIOSCRIPT_UPLOAD = 'updateaudioscriptupload';
export const PREVIOUS_ACTIVE_TO_PREVIEW = 'previousactivetopreview';
export const ASSIGN_THUMBNAIL = 'assignthumbnail';
export const CHANGEPROJECTNAMEINPREVIEW = 'changeprojectnameinpreview';
export const UPDATETHUMBNAIL = 'updatethumbnail';
export const EMPTYPREVIEW = 'emptypreview';
export const UPDATEPREVIEW = 'updatepreview';
export const UPDATESCREENPREVIEW = 'updatescreenpreview';
export const DELETESCREENPREVIEW = 'deletescreenpreview';
export const EDIT_SCREEN_FONT_STYLES = 'EDIT_SCREEN_FONT_STYLES';
export const UPDATE_AUDIO_UPLOAD = 'UPDATE_AUDIO_UPLOAD';
export const STYLES_DATA = 'STYLES_DATA';
export const UPDATE_PROJECT_SCREEN_PREVIEW = 'UPDATE_PROJECT_SCREEN_PREVIEW';
export const CLEAR_PREVIEW_GENERATED_OF = 'CLEAR_PREVIEW_GENERATED_OF';

export const GET_REMAINING_FEATURES = 'GET_REMAINING_FEATURES';

export const SHOW_CONTACT_SALES_MODAL = 'SHOW_CONTACT_SALES_MODAL';

export const SHOW_ERROR_LOG_REPORT_MODAL = 'SHOW_ERROR_LOG_REPORT_MODAL';
export const HIDE_ERROR_LOG_REPORT_MODAL = 'HIDE_ERROR_LOG_REPORT_MODAL';

/* UPLOAD DOCUMENT */
export const SET_PDF_UPLOAD_LIST = 'SET_PDF_UPLOAD_LIST';
export const SET_DRAG_OBJ = 'SET_DRAG_OBJ';
export const SET_PDF_REQUEST_ID = 'SET_PDF_REQUEST_ID';
export const SET_SELECTED_PDF_LIST_ID = 'SET_SELECTED_PDF_LIST_ID';

// SAVE AS DRAFT
export const SAVE_AS_DRAFT_CONTENT = 'SAVE_AS_DRAFT_CONTENT';
export const SAVE_AS_DRAFT_SEGMENT = 'SAVE_AS_DRAFT_SEGMENT';

// PROJECT CREATE SELECTION
export const WITH_SCRIPT_PROJECT_SELECTION = 'WITH_SCRIPT_PROJECT_SELECTION';
export const RESET_SCRIPT_PROJECT_SELECTION = 'RESET_SCRIPT_PROJECT_SELECTION';
export const UPLOADDOC_PROJECT_SELECTION = 'UPLOADDOC_PROJECT_SELECTION';
export const RESET_UPLOADDOC_PROJECT_SELECTION = 'RESET_UPLOADDOC_PROJECT_SELECTION';
export const COMPOSE_SCREEN_HEADING = 'COMPOSE_SCREEN_HEADING';
export const COMPOSE_SCREEN_PARAGRAPH = 'COMPOSE_SCREEN_PARAGRAPH';
export const COMPOSE_SCREEN_LIST = 'COMPOSE_SCREEN_LIST';
export const COMPOSE_VIDEO_MEDIA = 'COMPOSE_VIDEO_MEDIA';
export const COMPOSE_IMAGE_MEDIA = 'COMPOSE_IMAGE_MEDIA';
export const COMPOSE_VECTOR_MEDIA = 'COMPOSE_VECTOR_MEDIA';
export const RESET_COMPOSE_SCREEN_PROJECT_SELECTION = 'RESET_COMPOSE_SCREEN_PROJECT_SELECTION';
