import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { deletePromptIcon, dissmissIcon } from '../constants/icon';
import { bindActionCreators } from 'redux';
import { actionCreator } from '../store';
import { reportError } from './api.service';
import { showToaster } from './helper';
import { useLocation } from 'react-router';

export default function ErrorLogReportModal(props) {
  const { className } = props;
  let error = useSelector(state => state.errorLog);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const dispatch = useDispatch();
  const { hideErrorLogReportModal } = bindActionCreators(actionCreator, dispatch);

  const { pathname } = useLocation();

  const getProjectId = () => {
    if (
      [
        'render-detail',
        'edit-projects',
        'project-details',
        'project-preview',
        'generate-videos-inputs',
      ].some(v => pathname.includes(v))
    ) {
      return pathname.split('/').filter(Boolean)?.[1];
    }
    return false;
  };

  const modalClassName = `
    modal-dialog 
    custom-confirm-modal
    modal-dialog-centered 
    custom-modal 
    modal-md 
    ${className || ''}
  `;

  const handleOk = async () => {
    try {
      setIsSubmitting(true);

      const payload = {
        frontEndUrl: window.location.href,
        errorLogId: error?.errorId,
      };

      if (getProjectId()) {
        payload.metaData = JSON.stringify({ projectId: getProjectId() });
      }
      await reportError(payload);
      handleClose();
      showToaster('Error Reported successfully', 'success');
    } catch (error) {
      showToaster('Error Reported failed', 'error');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    hideErrorLogReportModal();
  };

  if (!error?.showErrorReportModal) {
    return null;
  }

  return (
    <Modal isOpen={error?.showErrorReportModal} fade={true} centered className={modalClassName}>
      <ModalBody>
        <div
          className="modal-close text-right"
          title="Close"
          data-bs-dismiss="modal"
          onClick={handleClose}
        >
          {dissmissIcon}
        </div>
        <div className="modal-icon bg-danger">{deletePromptIcon}</div>
        <h3>{'Error Occured!'}</h3>
        <p>{error?.error}</p>
        <p>Do you wish to report error?</p>

        <div className="modal-button-wrap">
          <button type="button" className="primary-btn" onClick={handleClose}>
            No, Cancel
          </button>
          <button
            type="button"
            className="bg-primary-btn disabled-button"
            disabled={isSubmitting}
            onClick={handleOk}
          >
            {isSubmitting ? (
              <>
                Report <Spinner animation="border" size="sm" />
              </>
            ) : (
              'Report'
            )}
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
}
